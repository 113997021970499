import React, {useEffect} from 'react';
import Layout from '../../../components/Layout';
import UserCreateEdit from '../../../components/users/UserCreateEdit';
import { navigate } from 'gatsby-link';
import { useUserContext } from '../../../context/UserContext';
import { Authority } from '../../../api/RoleManagementApi';

export default function UserEditPage( props ) {
    const { authorities } = useUserContext();
    useEffect(() => {
        authorities.includes(Authority.USER_MANAGEMENT)==false && navigate("/")
    },[]);
    return (
        <Layout allowAnonymous={false}>
            <UserCreateEdit type="edit" style={{minWidth:700, width:"60%", marginLeft: "auto", marginRight: "auto"}} id={props.params.userId}/>
        </Layout>
    )
}